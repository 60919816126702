import React from 'react'
import { Link } from 'gatsby'
import { Layout } from '../../../layouts'
import Seo from '../../../components/seo'
import { CONTENT } from '../../../content'
import Hero from '../../../components/Blocks/Hero/Hero'
import SiteTitle from '../../../components/Blocks/SiteTitle/SiteTitle'
import * as styles from './index.module.scss'

const IndexPublic = () => (
  <Layout>
    <Seo
      title={CONTENT.CONSULTATION.AUDIENCE.meta.title}
      description={CONTENT.CONSULTATION.AUDIENCE.description}
    />
    <SiteTitle />
    <Hero>
      <header>
        <h1>{CONTENT.CONSULTATION.AUDIENCE.title}</h1>
      </header>
      <div>
        <p>{CONTENT.CONSULTATION.AUDIENCE.intro}</p>
      </div>

      <div>
        <ul className={styles.ul}>
          {CONTENT.CONSULTATION.AUDIENCE.content.map(({ text, to, title }) => (
            <li key={text}>
              <Link to={to} title={title}>
                Vers la page {text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Hero>
  </Layout>
)

export default IndexPublic
